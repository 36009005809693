
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ManageFooter from '@/components/ManageFooter.vue';
import getURL from "@/functions/getURL";
import Compare from '@/components/Compare.vue';

@Component({components: {ManageFooter, Compare}})
export default class Manage extends Vue {
  private programModels: Array< {id: string; name: string}> = [];
  private selectedModel = (this.$cookies.get("current_model")===null?1:this.$cookies.get("current_model"));
  private rerenderToken = 0;
  private config1: object = {};
  private config2: object = {};

  async loadConfig1(configID: number, configName: string) {
    const backendUrl = getURL();
    const foreignData = await this.$http.get(backendUrl+'/load_config/'+ configID);
    foreignData.data['name'] = configName;
    console.log(foreignData.data);
    this.config1 = foreignData.data;
  }

  async loadConfig2(configID: number, configName: string) {
    if(this.config1 != {}) {
      const backendUrl = getURL();
      const foreignData = await this.$http.get(backendUrl+'/load_config/'+ configID);
      foreignData.data['name'] = configName;
      this.config2 = foreignData.data;
    }
  }

  constructor() {
    super();
    this.listProgramModels()
  }

  @Watch("selectedModel")
  modelPicked(value: number){
    this.$cookies.set("selected_model", value);
    this.config2 = {};
    this.config1 = {};
    this.rerenderToken++;
  }

  async listProgramModels(){
    try{
      const foreignData = await this.$http.get(getURL()+'/list_program_models');
      this.programModels = foreignData.data['program-models'];
    }catch(e){
      console.error("Could not find feature models.");
      console.error(e);
    }
  }


}
