
import { Component, Prop, Vue } from 'vue-property-decorator';
import SaveConfigButton from '@/components/SaveConfigButton.vue';

@Component({components: {SaveConfigButton}})
export default class SaveConfigForm extends Vue {
    @Prop() private error!: string;
    @Prop() private errorColor!: string;

    name = "";

    saveConfig() {
        this.$emit('save', this.name);
    }

}
