
import Tree from "@/components/Tree.vue";

import {Component, Vue, Watch} from 'vue-property-decorator';
import {TreeNode} from "@/types/TreeNode";
import CostContainer from "@/components/CostContainer.vue";
import Visualisation from "@/components/Visualisation.vue";
import {CostObject} from "@/types/CostObject";
import OptionContainer from "@/components/OptionContainer.vue";
import Value from '@/classes/Value';
import {FeatureModel} from '@/types/FeatureModel';
import featureModelToTree from '@/functions/featureModelToTree';
import transformFrontendValuesToBackendValues from "@/functions/transformFrontendValuesToBackendValues";
import getURL from '@/functions/getURL';
import searchTreeName from "@/functions/searchTreeName";
import SaveConfigForm from '@/components/SaveConfigForm.vue';
import SuggestConfig from "@/components/SuggestConfig.vue";
import VueCookies from "vue-cookies";
import BestConfigButton from '@/components/BestConfigButton.vue';

@Component({components:{SuggestConfig, OptionContainer, Tree, CostContainer, Visualisation, SaveConfigForm, BestConfigButton}})

export default class Home extends Vue {
  private trees: Array<TreeNode> = [];
  private cost: Array<CostObject> = [];
  private maxCosts: Array<CostObject> = [];
  private minCosts: Array<CostObject> = [];
  //private chartData: ChartData = {label: [], datasets: []}
  private deltas: any;
  private values: {[id: string]: Value} = {};
  private calcTimeout = 100;
  private featureModel: FeatureModel = {};
  private lastCalculation: {[id: string]: Value};
  private selectedModel = 1;
  private programModels: Array< {id: string; name: string}> = [];
  private selectedKPI = "";
  private configSavingError = "";
  private errorColor = "";
  private influenceList: Array<{name: string; value: string}> = [];

  constructor() {
    super();
    console.log(this.$cookies.get("current_model"))
    this.selectedModel = (this.$cookies.get("current_model")===null?1:this.$cookies.get("current_model"))
    this.selectedKPI = "energy_consumption_fixed(Ws;<)"
    this.lastCalculation = {};
    this.featureModel = {};
    this.listProgramModels();
    this.loadFeatureModel(this.selectedModel);
    this.updateMaxCosts(this.selectedModel);
    this.updateMinCosts(this.selectedModel);
  }



  @Watch("selectedModel")
  modelPicked(value: number){
    this.$cookies.set("current_model", value);
    this.loadFeatureModel(value);
    this.updateMaxCosts(value);
    this.updateMinCosts(value);
  }

  async updateInfluenceList(){
    const backendCompatibleValues = transformFrontendValuesToBackendValues(this.trees, this.values);
    const data = {"program_id": this.selectedModel, settings: backendCompatibleValues, "selected_kpi": this.selectedKPI};
    const foreignData = await this.$http.post(getURL()+'/find_most_expensive_options_by_kpi', data );
    const dataDict = foreignData.data;
    this.influenceList = [];

    for(const optionName in dataDict) {
        this.influenceList.push({
          name: optionName,
          value: dataDict[optionName]
        })
    }
    console.log(this.influenceList)
  }

  async listProgramModels(){
    try{
      const foreignData = await this.$http.get(getURL()+'/list_program_models');
      this.programModels = foreignData.data['program-models'];
    }catch(e){
      console.error("Could not find feature models.");
      console.error(e);
    }
  }

  async loadFeatureModel(id: number){
    //Reset object:
    this.values = {};

    this.lastCalculation = {};
    try {
      const backendUrl = getURL();

      const foreignData = await this.$http.get(backendUrl+'/feature_model/'+id);
      this.featureModel = foreignData.data

    }catch(e){
      console.error("Could not load feature model.");
      console.error(e);
      console.log("Falling back to default testing feature model.")
      this.featureModel = {
        1: { name: 'encryption', parent: "root", excludedoptions: [], optional: true, numeric: false },
        2: { name: 'compressed_script', parent: "root", excludedoptions: [], optional: true, numeric: false },
        3: { name: 'crypt_aes', parent: "encryption", excludedoptions: ['Crypt Blowfish'], optional: false, numeric: false },
        4: { name: 'crypt_blowfish', parent: "encryption", excludedoptions: ['Crypt AES'], optional: false, numeric: false },
        5: { name: 'option_1', parent: "crypt_blowfish", excludedoptions: [], optional: false, numeric: false },
        6: { name: 'option_2', parent: "crypt_blowfish", excludedoptions: [], optional: false, numeric: false },
        // eslint-disable-next-line @typescript-eslint/camelcase
        7: { name: 'option_3', parent: "crypt_aes", excludedoptions: [], optional: true, numeric: true, min_value: 1, max_value: 4096, step_function: "BlockSize * 2" },
      };
    }
    let prevVals = this.$cookies.get("current_tree");
    if (prevVals === null){
      prevVals = {};
    }

    this.trees = featureModelToTree(this.featureModel, this.values, prevVals);

    for (const key in this.values) {
      this.lastCalculation[key] = new Value(0)

    }

  }

  async requestCalculationFromServer(valuesForBackend: {[id: string]: number},urlExtension: string, updateVar: string){
    try{
      const backendUrl = getURL();
      const request = {"program_id": this.selectedModel, settings: valuesForBackend};
      const foreignData = await this.$http.post(backendUrl+urlExtension, request);
      return foreignData.data
    }catch(e){
      console.error("Couldn't reach server to calculate values.")
      console.error(e);
    }
  }

  async saveConfiguration(name: string) {
    console.log('Versuche, Konfig zu speichern' + name);
    try {
      const backendUrl = getURL();
      const backendCompatibleValues = transformFrontendValuesToBackendValues(this.trees, this.values);
      const configuration = {"program_id": this.selectedModel, "settings": backendCompatibleValues, "name": name};
      const foreignData = await this.$http.post(backendUrl+'/save_user_config', configuration, {withCredentials: true});
      const dictData = foreignData.data;
      console.log(dictData)
      if (!dictData["success"]) {
        this.errorColor = "color: #e41f1f;";
        this.configSavingError = dictData["message"];
        setTimeout(async ()=>{
          this.configSavingError = "";
        },2000);
      }
      else {
        this.errorColor = "color: green;";
        this.configSavingError = dictData["message"];
        setTimeout(async ()=>{
          this.configSavingError = "";
        },2000);
      }
    }
    catch(e) {
      console.error("Couldn't reach server to save configuration");
      console.error(e);
    }
  }

  //Compares the new submissions with the last calculation
  compareLastCalculation(values: {[id: string]: number}): boolean{
    for (const key in values){
      if (values[key]!==this.lastCalculation[key].value){
        return false;
      }
    }
    return true;
  }


  @Watch('trees', {deep: true, immediate: false})
  calculateCosts(){

    // Ensures that a configuration isn't calculated twice redundantly because of either rapid user
    // input or deep value changes.
    setTimeout(async ()=>{

      const backendCompatibleValues = transformFrontendValuesToBackendValues(this.trees,this.values);

      if (this.compareLastCalculation(backendCompatibleValues)){
        return;
      }
      for (const key in backendCompatibleValues){
        this.lastCalculation[key].value = backendCompatibleValues[key]
      }
      const dictData = await this.requestCalculationFromServer(backendCompatibleValues,'/calculate_cost','cost');
      this.cost = [];
      for(const nameRaw in dictData) {
        if (!dictData.hasOwnProperty(nameRaw))
          continue;
        const raw = nameRaw.split("(");
        const name = raw[0];
        const unit  = raw[1].split(";")[0];
        const value = dictData[nameRaw];
        const split = name.split("_");
        for(let i = 0; i < split.length; i++){
          split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
        }
        const displayName = split.join(" ");

        this.cost.push({
          kpiName: nameRaw,
          name: displayName,
          value: value,
          unit: unit,
          program_id: this.selectedModel
        })
      }
      this.deltas = await this.requestCalculationFromServer(backendCompatibleValues,'/calculate_delta/1','deltas');

      this.deltasUpdate()
    },this.calcTimeout)
  }

  @Watch('trees', {deep: true, immediate: false})
  saveCurrentConfigToCookie() {
    setTimeout(async ()=>{
      const backendCompatibleValues = transformFrontendValuesToBackendValues(this.trees,this.values);
      this.$cookies.set("current_tree", backendCompatibleValues , "1d");
    },this.calcTimeout);
  }

  deltasUpdate(){

    // Add deltas that are calculated after an excluded option is deactivated to the tree chain
    for (const optionName in this.deltas){
      if (!this.deltas.hasOwnProperty(optionName) || optionName == "kpis")
        continue;
      const option = this.deltas[optionName]
        for (const secondOptionName in option){
          if (!option.hasOwnProperty(secondOptionName) || optionName == "kpis")
            continue;
          this.deltas[secondOptionName] = option[secondOptionName];
        }
    }

    // Find max change by delta
    let max = 0

    for (const optionName in this.deltas){
      if (!this.deltas.hasOwnProperty(optionName)||! ("kpis" in this.deltas[optionName]))
        continue;
      const option = this.deltas[optionName]

      const kpiName = this.selectedKPI
      if (!(kpiName in option.kpis)){
        continue;
      }
      option.kpis[kpiName] = option.kpis[kpiName]*1
      if (max < Math.abs(option.kpis[kpiName])){
        max =  Math.abs(option.kpis[kpiName])
      }

    }

    for (const optionName in this.deltas){
      if (!this.deltas.hasOwnProperty(optionName))
        continue;
      const option = this.deltas[optionName]
      const node = searchTreeName(this.trees,optionName)
      if (node === undefined) {
        continue;
      }

      const kpiName =this.selectedKPI;
      if (max === 0|| !("kpis" in this.deltas[optionName])) { //Dont divide by 0 and set to 0 if invalid configuration (no deltas)
        node.delta = 0;
      } else {
        node.delta = option.kpis[kpiName] / max
      }
      node.delta *=-1;
      if (node.value.value === 1 || (node.numeric && !(node.value.value == node.minValue)) ){
        node.delta *=-1;
      }
    }
  }

  async updateMaxCosts(value: number){
    const foreignData = await this.$http.post(getURL()+'/most_expensive_config/'+value.toString());
    const dataDict = foreignData.data;
    this.maxCosts = [];

    for(const nameRaw in dataDict) {
      const raw = nameRaw.split("(");
      const name = raw[0];
      const unit = raw[1].split(";")[0];
      const value = dataDict[nameRaw];
      if (!value) {
        this.maxCosts = [];
        break;
      }
      const split = name.split("_");

      for(let i = 0; i < split.length; i++){
        split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
      }
      const displayName = split.join(" ");
      this.maxCosts.push({
        name: displayName,
        value: value,
        unit: unit,
        kpiName: this.selectedKPI,
        program_id: this.selectedModel
      })
    }
  }

  async updateMinCosts(value: number){
    const foreignData = await this.$http.post(getURL()+'/least_expensive_config/'+value.toString());
    const dataDict = foreignData.data;
    this.minCosts = [];

    for(const nameRaw in dataDict) {
      const raw = nameRaw.split("(");
      const name = raw[0];
      const unit = raw[1].split(";")[0];
      const value = dataDict[nameRaw];
      if (!value) {
        this.minCosts = [];
        break;
      }
      const split = name.split("_");

      for(let i = 0; i < split.length; i++){
        split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
      }
      const displayName = split.join(" ");
      this.minCosts.push({
        name: displayName,
        value: value,
        unit: unit,
        kpiName: this.selectedKPI,
        program_id: this.selectedModel
      })
    }
  }

  selectKpi(kpi: string){
    this.selectedKPI = kpi;
    this.deltasUpdate();
  }

}

