
import { Component, Prop, Vue } from 'vue-property-decorator';
import getURL from '@/functions/getURL';
import { CostObject } from '@/types/CostObject';
import fromJSON from '@/types/CostObject';
import SimpleCarousel from "vue2-simple-carousel";
import Visualisation from "@/components/Visualisation.vue";
import Cost from "@/components/Cost.vue";
import router from "@/router";

@Component({components: {SimpleCarousel, Visualisation}})
export default class TopConfigViewer extends Vue {
    private configs: Array<object> = [];
    private items = 3;
    private configCount = 3;
    private loaded = false;
    private rerenderToken = 0;
    @Prop() maxCosts!: Array<CostObject>;
    @Prop() minCosts!: Array<CostObject>;
    @Prop() private selectedModel!: number;

    loadConfig1(configID: number, configName: string){
      this.$emit('loadConfig1', configID, configName);
    }

    loadConfig2(configID: number, configName: string){
      this.$emit('loadConfig2', configID, configName);
    }

    loadSavedConfig(config: number){
      this.getConfigFromServer(config)
    }

    async getConfigFromServer(configID: number) {
      const backendUrl = getURL();
      const foreignData = await this.$http.get(backendUrl+'/load_config/'+ configID);
      this.$cookies.set("current_tree",foreignData.data.settings);
      router.push("/")

    }

    async userConfigs() {
      try {
        const foreignData = await this.$http.get(getURL()+'/get_popular_user_configs_by_program_id/' + this.selectedModel);
        const configArray = [];
        const configurations = foreignData.data['configurations'];
        for(const config of configurations) {
            const costArray: Array<CostObject> = [];
            for(const key in config.costs) {
                console.log(config)
                costArray.push(fromJSON(key, config['costs'][key], config['program_id']))
            }
            configArray.push({
                id: config['id'],
                name: config['name'],
                costs: costArray
            });
        }
        this.configCount = configArray.length
        const maxItems = Math.floor(window.innerWidth / 260 )
        this.items = maxItems < this.configCount ? maxItems : this.configCount
        this.configs = configArray
        this.loaded = true
        this.rerenderToken++
      }
      catch (error) {
        this.loaded = true;
      }
    }

    mounted() {
      this.userConfigs();
      window.addEventListener("resize", ()=> {
        //this.rerenderToken += 1
        const maxItems = Math.floor(window.innerWidth / 260 )
        this.items = maxItems < this.configCount ? maxItems : this.configCount
      });
    }

}
