
import { Component, Prop, Vue } from 'vue-property-decorator';
//import Vue2OrgTree from "@/components/org-tree/org-tree.vue";
import Option from "@/components/Option.vue";
import {TreeNode} from "@/types/TreeNode";

@Component({components:{Option}})
export default class Tree extends Vue {
  @Prop() private tree!: TreeNode;

  renderContent(h: any, data: TreeNode) {
    return h(Option,{ props:{data}});
  }

  labelClassName(data: TreeNode) {
    return (data.numeric||(!data.optional&&data.parent===undefined)?"":"option-clickable");
  }

  nodeClickEvent(e: any, data: TreeNode){
    if (!data.numeric&&!(!data.optional&&data.parent===undefined)){
      data.value.value = (data.value.value?0:1);
    }
  }
}
