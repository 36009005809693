

import VueChart from "vue-chartjs";
import {Component, Mixins, Prop, Vue} from "vue-property-decorator";

@Component
export default class BarChart extends Mixins(VueChart.Bar, VueChart.mixins.reactiveProp) {

    @Prop()
    public chartData!: object;

    @Prop()
    public options!: object;

    mounted (): void {
      this.renderChart(this.chartData, this.options)
    }
}
