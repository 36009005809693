import {TreeNode} from "@/types/TreeNode";
import Value from "@/classes/Value";
import searchTreeName from "./searchTreeName";

function recTreeWalkDisableAllChildren(treeNode: TreeNode, values: { [id: string]: number}){
  values[treeNode.label] = 0;
  for (let i = 0; i < treeNode.children.length; i++){
    recTreeWalkDisableAllChildren(treeNode.children[i],values);
  }
}

export default function transformFrontendValuesToBackendValues(trees: Array<TreeNode>, values: {[id: string]: Value}): {[id: string]: number}{
  const outputValues: {[id: string]: number} = {};
  for (const key in values){
    outputValues[key] = values[key].value*1;
  }
  for (const key in outputValues){
    if (!outputValues[key]){
      const treeNode = searchTreeName(trees,key);
      if (treeNode == undefined){
        console.error(key+" tree not found.")
        continue;
      }
      recTreeWalkDisableAllChildren(treeNode,outputValues)
    }
  }
  return outputValues;
}
