
  import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import LogoutButton from "@/components/LogoutButton.vue";
import SignUpButton from "@/components/SignUpButton.vue";
import LoginButton from "@/components/LoginButton.vue";

@Component({components:{LoginButton, SignUpButton, LogoutButton}})
export default class NavBar extends Vue {
  private menuOpen = false;
  @Prop() isLoggedIn!: boolean;


  openMenu(){
    this.menuOpen = true;
  }
  closeMenu(){
    this.menuOpen = false;
  }
}
