
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

  @Component
export default class Cost extends Vue {
    @Prop() private name!: string;
    @Prop() private value!: string;
    @Prop() private unit!: string;

    private aliases: { [name: string]: string } = {
      "Energy Consumption Fixed": "Energy",
      "Energy Consumption Bm": "Energy BM",
      "Run Time": "Runtime",
      "Cpu Load Fixed": "CPU Fixed",
      "Cpu Load Bm": "CPU BM"

    }

    get iconSrc(){
      let fileName = "settings-black-24dp";
      if (this.name.indexOf('Cpu')!==-1){
        fileName = "memory-24px";
      }
      if (this.name.indexOf('Time')!==-1){
        fileName = "schedule-24px";
      }
      if (this.name.indexOf('Energy')!==-1){
        fileName = "flash_on-24px";
      }
      if (this.name.indexOf('Compress')!==-1){
        fileName = "compress-24px";
      }
      return require("../assets/kpi-icons/"+fileName+".svg");
    }

    get valueUnitConversion(): string{
      let newVal = parseFloat(this.value);
      let newUnit = this.unit;
      switch (this.unit){
        case 'ms':
          if (newVal>1000){
            newVal = (newVal/1000);
            newUnit = ' s';
          }

          break;
        case 'B':
          if (newVal>1000){
            newVal = newVal/1000;
            newUnit = ' KB';
          }
          if (newVal>1000){
            newVal = newVal/1000;
            newUnit = ' MB';
          }
          break;
        case 'Ws':
          if (newVal>1000){
            newVal = newVal/1000;
            newUnit = ' kWs'
          }

      }
      const floatLength = (parseInt(newVal+"")+"").length;
      //Always exactly 5 digits
      return (newVal.toFixed(5-floatLength))+newUnit;
    }

    get labelAlias(): string{
      if (this.name in this.aliases){
        return this.aliases[this.name];
      }
      const split = this.name.split("_");
      for(let i = 0; i < split.length; i++){
        split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
      }
      return split.join(" ");
    }
}
