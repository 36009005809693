
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import beautifyOptionName from '@/functions/beautifyOptionName';
import {CostObject} from '@/types/CostObject';
import getURL from '@/functions/getURL';
import Spinner from '@/components/Spinner.vue';
import {
  breadcrumbTrail,
  highlightOnHover,
  nodeInfoDisplayer,
  sunburst,
  zoomOnClick
} from 'vue-d3-sunburst';
import "vue-d3-sunburst/dist/vue-d3-sunburst.css";
import * as d3 from 'd3';

@Component({components: {Spinner, sunburst, breadcrumbTrail, highlightOnHover, nodeInfoDisplayer, zoomOnClick}})
export default class Compare extends Vue {
    @Prop() config1!: {'program_id': string; 'user_id': string; 'settings': {[key: string]: any}; 'costs': {[key: string]: any}; 'name': string};
    @Prop() config2!: {'program_id': string; 'user_id': string; 'settings': {[key: string]: any}; 'costs': {[key: string]: any}; 'name': string};
    @Prop() selectedModel!: number;
    private optionList1: Array<string> = [];
    private optionList2: Array<string> = [];
    private costList1: {[key: string]: Array<string>} = {};
    private configInfluences: {
        "name": string;
        "children": [
            {
                "name": "increase";
                "children": [
                    {
                        "name": "Interaktionen";
                        "children": Array<object>;
                    },
                    {
                        "name": "Optionen";
                        "children": Array<object>;
                    }
                ];
            },
            {
                "name": "decrease";
                "children": [
                    {
                        "name": "Interaktionen";
                        "children": Array<object>;
                    },
                    {
                        "name": "Optionen";
                        "children": Array<object>;
                    }
                ];
            }
        ];
    }
     =
      {"name": "influences",
        "children": [
          {   "name": "increase",
              "children": [{
                  "name": "Interaktionen",
                  "children": []
              },
                {
                  "name": "Optionen",
          "children": []
      }]
      },
      {"name": "decrease",
      "children": [{
          "name": "Interaktionen",
          "children": []
      },{
          "name": "Optionen",
          "children": []
      }]}
      ]};
    private config1Influences: {[key: string]: {
        "name": string;
        "children": [
            {
                "name": "increase";
                "children": [
                    {
                        "name": "Interaktionen";
                        "children": Array<object>;
                    },
                    {
                        "name": "Optionen";
                        "children": Array<object>;
                    }
                ];
            },
            {
                "name": "decrease";
                "children": [
                    {
                        "name": "Interaktionen";
                        "children": Array<object>;
                    },
                    {
                        "name": "Optionen";
                        "children": Array<object>;
                    }
                ];
            }
        ];
    };} = {}
    private config2Influences: {[key: string]: {
        "name": string;
        "children": [
            {
                "name": "increase";
                "children": [
                    {
                        "name": "Interaktionen";
                        "children": Array<object>;
                    },
                    {
                        "name": "Optionen";
                        "children": Array<object>;
                    }
                ];
            },
            {
                "name": "decrease";
                "children": [
                    {
                        "name": "Interaktionen";
                        "children": Array<object>;
                    },
                    {
                        "name": "Optionen";
                        "children": Array<object>;
                    }
                ];
            }
        ];
    };} = {}
    private rerenderToken = 0;
    private loading = false;
    private negativeEmpty = d3.scaleOrdinal(['#ffffff', '#c70000', '#b5b5b5', '#8a8a8a'].concat(this.colorScaleSuffix()));
    private positiveEmpty = d3.scaleOrdinal(['#ffffff', '#00c707', '#b5b5b5', '#8a8a8a'].concat(this.colorScaleSuffix()));
    private colorScale1 = d3.scaleOrdinal(['#ffffff', '#c70000', '#00c707', '#b5b5b5', '#8a8a8a'].concat(this.colorScaleSuffix()));
    private colorScale2 = d3.scaleOrdinal(['#ffffff', '#c70000', '#00c707', '#b5b5b5', '#8a8a8a'].concat(this.colorScaleSuffix()));

    isInfluencesEmpty(influences: {
        "name": string;
        "children": [
            {
                "name": "increase";
                "children": [
                    {
                        "name": "Interaktionen";
                        "children": Array<object>;
                    },
                    {
                        "name": "Optionen";
                        "children": Array<object>;
                    }
                ];
            },
            {
                "name": "decrease";
                "children": [
                    {
                        "name": "Interaktionen";
                        "children": Array<object>;
                    },
                    {
                        "name": "Optionen";
                        "children": Array<object>;
                    }
                ];
            }
        ];
    }): boolean {
        return (influences.children[0].children[0].children.length == 0 
        && influences.children[0].children[1].children.length == 0
        && influences.children[1].children[0].children.length == 0
        && influences.children[1].children[1].children.length == 0)
    }

    hoverText(nodes: any): string {
        try {
            return beautifyOptionName(String(nodes.mouseOver.data.name))
        }
        catch {
            return "";
        }
    }

    shuffle(array: Array<string>): Array<string> {
        let currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    colorScaleSuffix(): Array<string> {
        const array = [];
        for(let i = 0; i < 50; i++) {
            const h = i/50;
            array.push(this.hsvToRgb(h, 0.5, 1))
        }
        return this.shuffle(array);
    }

    quadShuffle(input: Array<string>): Array<string> {
        const array = input;
        for(let i = 0; i < array.length - 3; i++) {
            const hold1 = array[i];
            const hold2 = array[i + 1];
            array[i] = array[i + 3];
            array[i + 1] = array[i + 2];
            array[i + 2] = hold2;
            array[i + 3] = hold1;
        }
        return array;
    }

    hsvToRgb(h: number, s: number, v: number): string {
        let r = 255, g = 255, b = 255;

        const i = Math.floor(h * 6);
        const f = h * 6 - i;
        const p = v * (1 - s);
        const q = v * (1 - f * s);
        const t = v * (1 - (1 - f) * s);

        switch (i % 6) {
            case 0: r = v, g = t, b = p; break;
            case 1: r = q, g = v, b = p; break;
            case 2: r = p, g = v, b = t; break;
            case 3: r = p, g = q, b = v; break;
            case 4: r = t, g = p, b = v; break;
            case 5: r = v, g = p, b = q; break;
        }

        r = Math.round(r * 255);
        g = Math.round(g * 255);
        b = Math.round(b * 255);

        return this.rgbToHex(r, g, b)
    }

    componentToHex(c: number): string {
        const hex = c.toString(16);
        // console.log(c + ": " + hex)
        return hex.length == 1 ? "0" + hex : hex;
    }

    rgbToHex(r: number, g: number, b: number): string {
        return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    }

    shadowColor(value: string) {
        let shadowColor = "rgba(0, 0, 0, 0.05)";
        if(Number(value) > 0) {
            shadowColor = "rgba("+ (Math.abs(Number(value))+0.5)*255+",0,0,"+(Math.abs(Number(value))*0.55+0.20) + ")";
        }
        else if (Number(value) < 0) {
            shadowColor = "rgba(0,"+(Math.abs(Number(value))+0.5)*255+",0,"+(Math.abs(Number(value))*0.55+0.20)+")";
        }
        return {
            '--shadow-color': shadowColor
        }
    }

    beautifyKPIName(nameRaw: string) {
        const raw = nameRaw.split("(");
        const name = raw[0];
        const unit = raw[1].split(";")[0];
        const split = beautifyOptionName(name).split(" ");
        const shortName = split[0] + " " + split[1];

        return shortName + " in " + unit;
    }

    beautifyKPINameNoUnit(nameRaw: string) {
        const shortName = this.beautifyKPIName(nameRaw);
        const split = shortName.split(" ");
        const shorterName = split[0] + " " + split[1];

        return shorterName;
    }

    async makeCostList() {
        this.config1Influences = {}
        this.config2Influences = {}
        for(const kpi in this.config1.costs) {
            const backendUrl = getURL();
            const data = {
                'program_id': this.config1.program_id,
                'settings': this.config1.settings,
                'selected_kpi': kpi
                }
            const foreignData = await this.$http.post(backendUrl + '/find_most_expensive_options_by_kpi', data);
            const costs = foreignData.data;

            const copyConfigInfluences = JSON.parse(JSON.stringify(this.configInfluences))
            for(const influence in costs) {
                // Positive
                if(Number(costs[influence]) > 0 ) {
                    // Interaktionen
                    if(influence.includes("#")) {
                        copyConfigInfluences.children[0].children[0].children.push(
                            {"name": influence, "size": Number(costs[influence])}
                        )
                    }
                    // Optionen
                    else {
                        copyConfigInfluences.children[0].children[1].children.push(
                            {"name": influence, "size": Number(costs[influence])}
                        )
                    }
                }
                //Negativ
                else if(Number(costs[influence]) < 0 ) {
                    // Interaktionen
                    if(influence.includes("#")) {
                        copyConfigInfluences.children[1].children[0].children.push(
                            {"name": influence, "size": Math.abs(Number(costs[influence]))}
                        )
                    }
                    // Optionen
                    else {
                        copyConfigInfluences.children[1].children[1].children.push(
                            {"name": influence, "size": Math.abs(Number(costs[influence]))}
                        )
                    }
                }
            }

            this.config1Influences[kpi] = copyConfigInfluences;
            if(this.config1Influences[kpi].children[0].children[0].children.length == 0 && this.config1Influences[kpi].children[0].children[1].children.length == 0) {
                this.colorScale1 = this.positiveEmpty
                console.log('positiveempty')
            }
            else if(this.config1Influences[kpi].children[1].children[0].children.length == 0 && this.config1Influences[kpi].children[1].children[1].children.length == 0) {
                this.colorScale1 = this.negativeEmpty
            }
            else {
                this.colorScale1 = d3.scaleOrdinal(['#ffffff', '#c70000', '#00c707', '#b5b5b5', '#8a8a8a'].concat(this.colorScaleSuffix()));
            }


            this.costList1[kpi] = [];
            const len = this.optionList1.length;
            for(let i = 0; i < len; i++) {
                // console.log(this.optionList1[i])
                if(this.optionList1[i] == "" || !costs[this.optionList1[i]]) {
                    // console.log('Hallo, ich bin der If Block')
                    this.costList1[kpi].push('0.0')
                }
                else {
                    this.costList1[kpi].push(Number(costs[this.optionList1[i]]).toFixed(3))
                }
            }
        }
        for(const kpi in this.config2.costs) {
            const backendUrl = getURL();
            const data = {
                'program_id': this.config2.program_id,
                'settings': this.config2.settings,
                'selected_kpi': kpi
                }
            const foreignData = await this.$http.post(backendUrl + '/find_most_expensive_options_by_kpi', data);
            const costs = foreignData.data;

          const copyConfigInfluences = JSON.parse(JSON.stringify(this.configInfluences))
          for(const influence in costs) {
            // Positive
            if(Number(costs[influence]) > 0 ) {
              // Interaktionen
              if(influence.includes("#")) {
                copyConfigInfluences.children[0].children[0].children.push(
                  {"name": influence, "size": Number(costs[influence])}
                )
              }
              // Optionen
              else {
                copyConfigInfluences.children[0].children[1].children.push(
                  {"name": influence, "size": Number(costs[influence])}
                )
              }
            }
            //Negativ
            else if(Number(costs[influence]) < 0 ) {
              // Interaktionen
              if(influence.includes("#")) {
                copyConfigInfluences.children[1].children[0].children.push(
                  {"name": influence, "size": Math.abs(Number(costs[influence]))}
                )
              }
              // Optionen
              else {
                copyConfigInfluences.children[1].children[1].children.push(
                  {"name": influence, "size": Math.abs(Number(costs[influence]))}
                )
              }
            }
          }

            this.config2Influences[kpi] = copyConfigInfluences;
            if(this.config2Influences[kpi].children[0].children[0].children.length == 0 && this.config2Influences[kpi].children[0].children[1].children.length == 0) {
                this.colorScale2 = this.positiveEmpty
                console.log('positiveempty')
            }
            else if(this.config2Influences[kpi].children[1].children[0].children.length == 0 && this.config2Influences[kpi].children[1].children[1].children.length == 0) {
                this.colorScale2 = this.negativeEmpty
            }
            else {
                this.colorScale2 = d3.scaleOrdinal(['#ffffff', '#c70000', '#00c707', '#b5b5b5', '#8a8a8a'].concat(this.colorScaleSuffix()));
            }
        


          const len = this.optionList1.length;
            for(let i = 0; i < len; i++) {
                if(this.optionList2[i] != "" && costs[this.optionList2[i]] != undefined) {
                    this.costList1[kpi][i] = (Number(costs[this.optionList2[i]]) - Number(this.costList1[kpi][i])).toFixed(3);
                }
            }
        }
    }

    @Watch('config1')
    @Watch('config2')
    async makeOptionLists() {
        if(this.config1['costs'] && this.config2['costs']) {
            this.loading = true;
            this.optionList1 = [];
            this.optionList2 = [];
            const config1EnabledOptions = [];
            const config2EnabledOptions = [];
            for(const option in this.config1.settings) {
                if(this.config1.settings[option] > 0) {
                    config1EnabledOptions.push(option);
                }
            }
            for(const option in this.config2.settings) {
                if(this.config2.settings[option] > 0) {
                    config2EnabledOptions.push(option);
                }
            }
            const len = config1EnabledOptions.length;
            for(let i = 0; i < len; i++) {
                const option = String(config1EnabledOptions.shift());

                if(config2EnabledOptions.indexOf(option) > -1) {
                    // console.log(option)
                    config2EnabledOptions.splice(config2EnabledOptions.indexOf(option), 1);
                    // console.log(config2EnabledOptions);
                    this.optionList2.push(option);
                }
                else {
                    this.optionList2.push('');
                }
                this.optionList1.push(option);
            }
            for(let i = 0; i < config2EnabledOptions.length; i++) {
                const option = config2EnabledOptions[i];
                this.optionList2.push(option);
                this.optionList1.push('');
            }

            await this.makeCostList();
            this.beautifyOptionLists();
            this.loading = false;
            this.$emit('optionListDone');

            this.rerenderToken++;
        }

    }

    beautifyOptionLists() {
        for(let i = 0; i < this.optionList1.length;  i++) {
            if (this.config1.settings[this.optionList1[i]] > 1) {
                this.optionList1[i] = this.optionList1[i] + ": "+ this.config1.settings[this.optionList1[i]]
            }
            this.optionList1[i] = beautifyOptionName(this.optionList1[i])
        }
        for(let i = 0; i < this.optionList2.length;  i++) {
            if (this.config2.settings[this.optionList2[i]] > 1) {
                this.optionList2[i] = this.optionList2[i] + ": "+ this.config2.settings[this.optionList2[i]]
            }
            this.optionList2[i] = beautifyOptionName(this.optionList2[i])
        }
    }


}
