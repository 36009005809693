
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import Cost from "@/components/Cost.vue";
import { CostObject } from "@/types/CostObject";
import getURL from "@/functions/getURL";
import {TreeNode} from "@/types/TreeNode";
import transformFrontendValuesToBackendValues from "@/functions/transformFrontendValuesToBackendValues";
import Value from "@/classes/Value";
import Spinner from "@/components/Spinner.vue";
import searchTreeName from "@/functions/searchTreeName";
import axios from 'axios'
@Component({components:{Spinner, Cost}})

export default class CostContainer extends Vue {
  @Prop() private trees!: Array<TreeNode>;
  @Prop() private values!: {[id: string]: Value};
  @Prop() private selectedModel!: number;
  @Prop() private selectedKPI!: string;

  private suggestions: Array<Array<string>> = [];
  private depthoptions = [2,3];
  private depth = 2;
  private cancelToken = axios.CancelToken.source();

  private loading = false;

  @Watch('selectedModel')
  clearSuggestions(){
    this.suggestions = [];
    this.cancelToken.cancel();
  }

  selectSuggestion(index: number){
    // Cache for all the values that need to be changed
    for (let i = 0; i < this.suggestions[index][0].length; i++){
      const step = this.suggestions[index][0][i];
      const node = searchTreeName(this.trees, step);
      if (node !== undefined && node.numeric && node.maxValue !== undefined && node.minValue !== undefined){
        if (this.backendValues[step]==node.minValue){
          this.backendValues[step] = node.maxValue;
        }else{
          this.backendValues[step] = node.minValue;
        }
      }else{
        this.backendValues[step] = Math.abs(this.backendValues[step]-1)
      }


    }
    for (const step in this.backendValues){
      const node = searchTreeName(this.trees, step);
      if (node){
        node.value.value = this.backendValues[step]
      }
    }

    this.suggestions = []
  }

  async requestCalculationFromServer(valuesForBackend: {[id: string]: number},urlExtension: string){
    this.cancelToken = axios.CancelToken.source()
    try{
      const backendUrl = getURL();
      const request = {"program_id": this.selectedModel, settings: valuesForBackend};
      const foreignData = await this.$http.post(backendUrl+urlExtension, request,{cancelToken: this.cancelToken.token});
      return foreignData.data
    }catch(e){
      if(axios.isCancel(e)) {
        return {}
      }
      console.error("Couldn't reach server to calculate values.")
      console.error(e);

    }
  }

  parseLabel(label: string){
    return (label.replace(/([A-Z])/g, ' $1').split("_").map(name => name.charAt(0).toUpperCase()+name.slice(1)).join(" "))
  }

  getPathsRec(deltas: any, pathsofar: Array<string>): any{
    let output = []
    if ("kpis" in deltas){
      output.push([pathsofar, Math.round(parseFloat(deltas.kpis[this.selectedKPI])*100)/100]);
      delete deltas["kpis"];
    }
    for (const key in deltas){
      if (!deltas.hasOwnProperty(key)){
        continue;
      }
      const calc = this.getPathsRec(deltas[key],pathsofar.concat([key]));
        output = output.concat(calc);
    }
    return output;
  }

  private backendValues:  {[id: string]: number} = {};
  private lastSearchedValues: {[id: string]: number} = {}
  async getSuggestions(){
    this.backendValues = transformFrontendValuesToBackendValues(this.trees, this.values);
    this.lastSearchedValues = {}
    for (const key in this.values){
      this.lastSearchedValues[key] = this.values[key].value
    }
    this.loading = true;
    const deltas = await this.requestCalculationFromServer(this.backendValues ,'/calculate_delta/'+this.depth);
    let paths = this.getPathsRec(deltas,[]);
    paths = paths.filter((currentValue: any)=>{
      if (isNaN(currentValue[1]) || currentValue[0].length == 0 ){
        return false;
      }
      return true;
    })
    paths = paths.sort((a: Array<number>,b: Array<number>)=>{
      if (a[1]<b[1]){
        return -1
      }
      if (b[1]<a[1]){
        return 1
      }
      return 0
    })
    console.log(paths);

    this.suggestions = paths.slice(0,3)
    this.loading = false;
  }

  @Watch("trees",{deep:true})
  treeChange(newTree: Array<TreeNode>){
    // Cancel suggestion search when values were changed.
    for (const key in this.lastSearchedValues){
      const node = searchTreeName(newTree,key);
      if (node){
        if (node.value.value!=this.lastSearchedValues[key]){
          this.clearSuggestions();
          return;
        }
      }
    }

  }
}
