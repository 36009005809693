
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import BarChart from "@/components/BarChart.vue";
    import { ChartData } from "@/types/ChartData";
    import { CostObject } from '@/types/CostObject';
    import ConfigInfluenceChart from "@/components/ConfigInfluenceChart.vue";
    // import material-icons from 'material-design-icons';

    @Component({components: {ConfigInfluenceChart, BarChart}})
    export default class Visualisation extends Vue {
      @Prop() private costs!: Array<CostObject>;
      @Prop() private detailViewEnabled!: boolean;
      @Prop() private minCosts!: Array<CostObject>;
      @Prop() private maxCosts!: Array<CostObject>;
      @Prop() private optionInfluenceList!: Array<{name: string; value: string}>;
      private open = false;
      private rerenderToken = 0;
      private colorMap: {[name: string]: string} = {
        "Energy": "#ffc824",
        "Cpu": "#ff4a4a",
        "Run": "#4a92ff",
        "Compression": "#ff3bd4"
        }

      closeVisualisation(){
        this.open = false;

      }

      openVisualisation(){
        if (!this.open && this.detailViewEnabled){
          this.open = true;
          this.rerenderToken++;
          this.$emit("openVisualisation")
        }
      }

      get openClass(): string{
        return (this.open?'open':'')
      }

      findMatchingMaxCost(name: string, program_id: number): CostObject {
        for(const m of this.maxCosts) {
          if(m.name === name && m.program_id === program_id) {
            return m
          }
        }
        return {
              name: "Fallback",
              value: "500",
              unit: "Kartoffeln",
              kpiName: "fall-back",
              program_id: 0
            };
      }

      findMatchingMinCost(name: string, program_id: number): CostObject {
        for(const m of this.minCosts) {
          if(m.name === name && m.program_id === program_id) {
            return m
          }
        }
        return {
              name: "Fallback",
              value: "0",
              unit: "Kartoffeln",
              kpiName: "fall-back",
              program_id: 0
            };
      }

      get processedCosts(): ChartData{
        const chartData: ChartData = {datasets: []};
        for (const cost of this.costs){
          const name = cost.name;
          const unit  = cost.unit;
          const firstName = cost.name.split(" ")[0];
          const color = this.barColor(firstName);

          //----------- Prozentwerte ausrechnen ---------------
          const max_cost = this.findMatchingMaxCost(cost.name, cost.program_id);
          const min_cost = this.findMatchingMinCost(cost.name, cost.program_id);
          const relativecost = ((parseFloat(cost.value) - parseFloat(min_cost.value)) / (parseFloat(max_cost.value) - parseFloat(min_cost.value))) * 100;

          chartData.datasets.push({
            label: name,
            backgroundColor: color + "aa",
            borderColor: color,
            borderWidth: 2,
            yAxisID: "relative",
            data: [relativecost]})
        }
        // console.log(chartData)
        return chartData;
      }

      iconFontLabel(name: string){
        // console.log(name)
        let iconName = "settings";
        if (name.indexOf('Cpu')!==-1){
          iconName = "memory";
        }
        if (name.indexOf('Time')!==-1){
          iconName = "schedule";
        }
        if (name.indexOf('Energy')!==-1){
          iconName = "flash_on";
        }
        if (name.indexOf('Compress')!==-1){
          iconName = "compress";
        }
        return iconName
      }

      get smallChartData(): object {
        const chartData: {
          labels: Array<string>;
          datasets: Array<{
            label: string;
            backgroundColor: Array<string>;
            borderColor: Array<string>;
            borderWidth: number;
            yAxisID: string;
            data: Array<number>;
        }>;} = {
          labels: [],
          datasets: [{
            label: "",
            backgroundColor: [],
            borderColor: [],
            data: [],
            borderWidth:  2,
            yAxisID: "relative",
        }]};

        for (const cost of this.costs){
          const name = cost.name;
          const unit  = cost.unit;
          const firstName = cost.name.split(" ")[0];
          const color = this.barColor(firstName);

          //----------- Prozentwerte ausrechnen ---------------

          const max_cost = this.findMatchingMaxCost(cost.name, cost.program_id);
          const min_cost = this.findMatchingMinCost(cost.name, cost.program_id);
          const relativecost = ((parseFloat(cost.value) - parseFloat(min_cost.value)) / (parseFloat(max_cost.value) - parseFloat(min_cost.value))) * 100;

          chartData.datasets[0].backgroundColor.push(color + "aa");
          chartData.datasets[0].borderColor.push(color);
          chartData.datasets[0].data.push(relativecost);
          chartData.labels.push(this.iconFontLabel(name))
        }
        return chartData;
      }

      private chartOptions = {
        scales: {
          yAxes: [{
            id: "relative",
            type: 'linear',
            position: 'left',
            scaleLabel: {
              display: true,
              labelString: "relative to most and least expensive config"
            },
            ticks: {
              max: 100,
              beginAtZero: true
              //min: Wert der billigsten Konfig (?)
            }
          }], xAxes: []
        },
        maintainAspectRatio: false,
        responsive: true,
        layout: {
          padding: {
            left: 0,
            right: 20,
            bottom: 0,
            top: 0
          }
        },
        tooltips: {
          callbacks: {
            title(tooltipItem: object, data: object) {
              return ''
            },
            label: function(tooltipItem: {datasetIndex: number}, data: ChartData) {
              return String(data.datasets[tooltipItem.datasetIndex].data) + "%"
            }
          }
        }
      }


      get smallChartOptions(): object {
        const chartOptions = {
          scales: {yAxes: Array<object>(), xAxes: Array<object>()},
          legend: {},
          layout: {},
          maintainAspectRatio: false,
          title: {},
          tooltips: {}
          };

        chartOptions.scales.yAxes.push({
            id: "relative",
            type: 'linear',
            position: 'left',
            scaleLabel: {
              display: false,
              labelString: "relative to most/least expensive"
            },
            ticks: {
              max: 100,
              min: 0,
              stepSize: 50,
              callback: function(label: number) {
                switch(label) {
                  case 0:
                    return 'min';
                  case 50:
                    return '';
                  case 100:
                    return 'max';
                }
              }
            }
          })

        chartOptions.scales.xAxes.push({
          ticks: {
            fontFamily: 'material-icons',
            fontSize:18,
            padding:0,
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0
          }
        })

        chartOptions.legend = {
          display: false,
        }

        chartOptions.layout = {
          padding: {
            bottom: 0,
            left: 0,
            top: 0,
            right: 0
          }
        }

        chartOptions.title = {
          display: true, //TODO: Achsen tatsächlich mit teuerster Konfig als Max. Wert
          text: 'relative to most and least expensive'
        }

        chartOptions.tooltips = {
          callbacks: {
            title(tooltipItem: object, data: object) {
              return ''
            },
            label: function(tooltipItem: {index: number}, data: ChartData) {
              return String(data.datasets[0].data[tooltipItem.index]) + "%"
            }
          }
        }

        return chartOptions;
      }

      barColor(firstName: string): string {
        let color;
        try {
          color = this.colorMap[firstName]
        }
        catch {
          color = "#fafafa"
        }

        return color;
      }


    }
