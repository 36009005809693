import { TreeNode } from '@/types/TreeNode';



function searchTreeIDRec(tree: TreeNode, id: number): TreeNode | undefined {
  if (tree.id === id) {
    return tree;
  }
  for (let i = 0; i < tree.children.length; i++) {
    const result = searchTreeIDRec(tree.children[i], id);
    if (result) {
      return result;
    }
  }
  return undefined;
}

export default function searchTreeID(trees: Array<TreeNode>, id: number): TreeNode | undefined{
  let output;
  for (let i = 0; i < trees.length; i++){
    output = searchTreeIDRec(trees[i], id);
    // Beende den loop wenn der parent gefunden wurde.
    if (output){
      return output;
    }
  }
  return undefined;
}
