
import {Component, Prop, Vue} from 'vue-property-decorator';
import getURL from "@/functions/getURL";
import passwordIsValid from '@/functions/passwordIsValid';
import emailIsValid from '@/functions/emailIsValid';

@Component
export default class SignUpForm extends Vue {
  password = "";
  repeatPassword = "";
  email = "";
  signupReturnMsg = "";
  notificationCss = "color: red;";


  get isValidSignUp(){
    return( passwordIsValid(this.password) && emailIsValid(this.email) && this.repeatPassword === this.password)
  }

  get notificationContent(){
    if (!passwordIsValid(this.password) && this.password.length > 1){
      return "Invalid Password\n(More than 6 letters required)"
    }
    if (!emailIsValid(this.email) && this.email.length > 1){
      return "Invalid E-Mail"
    }
    if (this.repeatPassword !== this.password){
      return "Repeated Password is not the same as original password."
    }

    return this.signupReturnMsg;
  }

  async signUp() {
    try{
      const data =  {"email": this.email, "password": this.password};
      const foreignData = await this.$http.post(getURL()+'/sign_up', data,{withCredentials: true});
      const dictData = foreignData.data;
      console.log(dictData.authenticated);
      if(dictData.authenticated === true){
        this.notificationCss = "color: green";
        this.signupReturnMsg = "Successfully signed up!";
        this.$emit("loggedIn");
        setTimeout(()=>{
          this.$router.push("/")
        },200)
      }else {
        if (dictData.error){
          this.signupReturnMsg= dictData.error
        }else{
          this.signupReturnMsg = "Unknown Error occured."
        }
      }
    } catch(e){
      console.error(e);
      this.signupReturnMsg= "Couldn't reach server";
    }
  }

}
