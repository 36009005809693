import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Configurator from '../views/Configurator.vue';
import Manage from '../views/Manage.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/manage',
    name: 'Manage',
    component: Manage,
  },
  {
    path: '/',
    name: 'Configurator',
    component: Configurator,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/sign-up',
    name: 'Sign up',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SignUp.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
