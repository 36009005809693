
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import {TreeNode} from '@/types/TreeNode';

    @Component({components: {}})
    export default class NumericRange extends Vue {
      @Prop() private data!: TreeNode;

      private sliderPosition = 0;

      @Watch('data.value.value',{deep:true, immediate:false})
      updateSliderPosition(newVal: any): void{
        this.sliderPosition = this.valueArray.indexOf(newVal*1);
      }

      get valueArray(): Array<number>{
        //This is mainly to make TypeScript stfu
        if (this.data.stepFunction === undefined || this.data.minValue === undefined || this.data.maxValue === undefined) {
          return [];
        }

        const output = [];
        const stepFunctionArray = this.data.stepFunction.split(" ");

        let stepFunction = function (i: number) {
          return i+1;
        }; //Default step function

        switch (stepFunctionArray[1]) {
          case "+":
            stepFunction = function (i: number) {
              return i + parseInt(stepFunctionArray[2]);
            }
            break;
          case "*":
            stepFunction = function (i: number) {
              return i * parseInt(stepFunctionArray[2]);
            }
        }
        for (let i = this.data.minValue*1; i <= this.data.maxValue; i=stepFunction(i)) {
          output.push(i);
        }
        return output;
      }



      private rangeChange(e: {target: {value: number}}){
        this.data.value.value = this.valueArray[e.target.value];
      }

    }
