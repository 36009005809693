// Konvertiert das featuremodell zu einer rekursiven Baum Datenstruktur die von dem vue2-org-tree modul gelesen werden kann.
import {FeatureModel} from '@/types/FeatureModel';
import {TreeNode} from '@/types/TreeNode';
import searchTreeID from './searchTreeID';
import Value from "@/classes/Value";
import searchTreeName from "@/functions/searchTreeName";
import {FeatureModelEntry} from "@/types/FeatureModelEntry";

function optionToNode(id: number, values: {[id: string]: Value}, option: FeatureModelEntry, parent: TreeNode | undefined, savedValue: number | undefined): TreeNode{
  const value = new Value(0);
  // Aktivier die Node, wenn es dass erste Kind ist und nicht optional, oder der parent root ist
  if (!option.optional && ((parent !== undefined && parent.children.length === 0) || parent===undefined)){
    value.value = 1;
  }
  if (option.min_value){
    value.value = option.min_value;
  }
  if (savedValue!=undefined){
    value.value = savedValue;
  }
  values[option.name] = value;

  return { id: id, label: option.name, children: [], value, excludedoptions:[],
    optional: option.optional, parent, expand: (value.value!==0), numeric: option.numeric,
  maxValue: option.max_value, minValue: option.min_value, stepFunction: option.step_function,
  delta:0};
}

export default function featureModelToTree(featuremodel: FeatureModel, values: {[id: string]: Value}, savedConfigs: {[id: string]: number} = {}): Array<TreeNode> {
  const outputtrees: Array<TreeNode> = []
  for (const key in featuremodel){
    if (!featuremodel.hasOwnProperty(key)|| featuremodel[key].name==='root'){
      continue;
    }
    const option = featuremodel[key];
    if (option.parent === "root"){
      //Füge eine neue Root zu der Baumliste hinzu
      outputtrees.push(optionToNode(parseInt(key),values, option, undefined, savedConfigs[option.name]));
    }else{
      //Such nach dem parent und füg die Node als Kind hinzu
      const parent = searchTreeName(outputtrees, option.parent);
      if (!parent){
        //Invalide parent id.
        console.error('Parent '+option.parent+' not found.');
        continue;
      }
      parent.children.push(optionToNode(parseInt(key), values, option, parent, savedConfigs[option.name]));
    }
  }
  //Excluded Options müssen nach dem erstellen des Trees hinzugefügt werden.
  for (const key in featuremodel){
    if (!featuremodel.hasOwnProperty(key)){
      continue;
    }
    for (let i = 0; i < featuremodel[key].excludedoptions.length; i++){

      const constraint = featuremodel[key].excludedoptions[i]
      const excludednode = searchTreeName(outputtrees, constraint);

      if (!excludednode){
        // Fehler
        console.error('Node ' + constraint + ' not found.');
        continue;
      }
      const node = searchTreeID(outputtrees, parseInt(key));
      if (!node){
        console.error("Node "+key+" not found.");
        continue;
      }
      node.excludedoptions.push(excludednode);
    }
  }

  return outputtrees;
}
