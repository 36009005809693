
import getURL from '@/functions/getURL';
import { Component, Prop, Vue } from 'vue-property-decorator';
import saveConfiguration from '@/views/Configurator.vue'

@Component
export default class SaveConfigButton extends Vue {
  async saveConfig() {
    this.$emit('click');
  }
}
