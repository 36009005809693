

import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
import getURL from '@/functions/getURL';
import {TreeNode} from '@/types/TreeNode';
import searchTreeName from '@/functions/searchTreeName';

@Component
export default class BestConfigButton extends Vue {
    @Prop() private KPIName!: string;
    @Prop() private programID!: number;
    @Prop() private trees!: Array<TreeNode>;

    async getBestConfig() {
        const data = {"kpi": this.KPIName, "program_model_id": this.programID};
        const foreignData = await this.$http.post(getURL()+'/load_best_config_for_kpi', data, {withCredentials: true});

        const settings = foreignData.data['settings'];
        console.log('Settings: ' + settings);
        for (const option in settings){
            const node = searchTreeName(this.trees, option);
            if (node){
                node.value.value = settings[option]
            }
        }
    }

}
