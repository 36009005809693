
import {Component, Prop, Vue} from 'vue-property-decorator';
import passwordIsValid from "@/functions/passwordIsValid";
import emailIsValid from "@/functions/emailIsValid";
import getURL from '@/functions/getURL';

@Component
export default class LoginForm extends Vue {
  email = "";
  loginReturnMsg = "";
  password = "";
  notificationCss = "color: red;";


  get isValidSignUp(){
    return emailIsValid(this.email);
  }

  get notificationContent(){
    if (!emailIsValid(this.email)){
      return "Invalid E-Mail"
    }
    return this.loginReturnMsg;
  }

  async Login() {
    try{
      const data =  {"email": this.email, "password": this.password};
      const foreignData = await this.$http.post(getURL()+'/login', data, {withCredentials: true});
      const dictData = foreignData.data;
      if(dictData.authenticated === "true") {
        this.notificationCss = "color: green";
        this.loginReturnMsg = "Successfully logged in";
        this.$emit("loggedIn");
        setTimeout(()=>{
          this.$router.push("/")
        },200)
      }else{
        if (dictData.error){
          this.loginReturnMsg = dictData.error
        }else{
          this.loginReturnMsg = "Unknown Error occured."
        }
      }
    } catch(e) {
      console.error(e);
      this.loginReturnMsg = "Couldn't reach server";
    }
  }
}
