
  import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
  import {TreeNode} from "@/types/TreeNode";
  import NumericRange from "@/components/NumericRange.vue";

    @Component({components: {NumericRange}})
    export default class Option extends Vue {
      @Prop() private data!: TreeNode;

      get boxShadowColor(){
        if (this.data.delta==0){
          return "(0,0,0,0)";
        }
        if (this.data.delta<0){
          return "("+(Math.abs(this.data.delta)+0.5)*255+",0,0,"+(Math.abs(this.data.delta)*0.55+0.20)+")"
        }else{
          return "(0,"+(Math.abs(this.data.delta)+0.5)*255+",0,"+(Math.abs(this.data.delta)*0.55+0.20)+")"
        }
      }

      parseLabel(){
        return (this.data.label.replace(/([A-Z])/g, ' $1').split("_").map(name => name.charAt(0).toUpperCase()+name.slice(1)).join(" "))
      }

      private lastvalue = this.data.value.value;

      @Watch('data.value.value', {immediate: false})
      onValueChange(value: boolean){
        if (this.data.children) {
          this.data.expand = value;
        }

        if (value) {
          // Schalte excluded options aus wenn die checkbox aktiviert wird.
          for (let i = 0; i < this.data.excludedoptions.length; i++) {
            this.data.excludedoptions[i].value.value = 0;
          }
          // Falls die Kinder nicht optional sind und alle deaktiviert sind, aktiviere den ersten.
          let activechildren = 0;
          let optional = true;
          for (let i = 0; i < this.data.children.length; i++){
            if (this.data.children[i].optional===false){
              optional = false;
            }
            if (this.data.children[i].value.value !== 0){
              activechildren++;
            }
          }
          if (optional === false && activechildren === 0){
            this.data.children[0].value.value = 1;
          }
        } else {
          // Schalte andere options an, wenn die checkbox deaktiviert ist, nicht optional ist und alle anderen Kinder aus sind
          if (!this.data.optional&&this.data.parent!==undefined) {
            // Checkt ob andere kinder aktiviert sind und sucht die position der Node im Parent
            let positioninparent = 0;
            for (let i = 0; i < this.data.parent.children.length; i++) {
              const otherchild = this.data.parent.children[i];
              if (otherchild.id === this.data.id) {
                positioninparent = i;
              }
              if (otherchild.value.value) {
                return;
              }
            }
            const nextchild = ((positioninparent + 1) === this.data.parent.children.length ? this.data.parent.children[0] : this.data.parent.children[(positioninparent + 1)]);
            nextchild.value.value = 1;
          }
        }
      }


    }
