
import { Component, Prop, Vue } from 'vue-property-decorator';

import NavBar from "@/components/NavBar.vue";
import getURL from "@/functions/getURL";
@Component({
  components: {NavBar}
})
export default class App extends Vue {

  isLoggedIn = false;

  created(){
    this.checkLogin();
  }

  async checkLogin() {
    try{
      const foreignData = await this.$http.get(getURL()+'/is_logged_in', {withCredentials: true});
      const dictData = foreignData.data;
      if(dictData.success === true) {
        this.isLoggedIn = true;
      }
    } catch(e) {
      console.error(e);
    }
  }

}
