
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import Cost from "@/components/Cost.vue";
import { CostObject } from "@/types/CostObject";
@Component({components:{Cost}})
export default class CostContainer extends Vue {
  @Prop() private costs!: Array<CostObject>;
  private selectedKPI = "energy_consumption_fixed(Ws;<)"
  private scrollY = 0;

  created(){
    document.addEventListener('scroll', ()=>{this.scrollY = window.scrollY});
    console.log(scrollY);
  }

  get processedCosts(): Array<CostObject>{
    const output = [];
    for (const cost of this.costs){
      const valueRound = parseFloat(cost.value).toFixed(4);

      output.push({name: cost.name, value: valueRound, unit: cost.unit, kpiName:cost.kpiName, program_id:cost.program_id })
    }
    console.log(output);
    return output;
  }

  @Watch('selectedKPI')
  kpiChange(){
    this.$emit('KPIChange',this.selectedKPI);
  }
}
