
import {Component, Prop, Vue} from 'vue-property-decorator';
import Tree from "@/components/Tree.vue";

@Component({components: {Tree}})
export default class ConfigInfluenceChart extends Vue {
  @Prop() influenceList!: Array<{name: string; value: string}>;
  private items = 3;
  private page = 0;

  get maxPages(): number{
    return Math.floor(this.influenceList.length/3);
  }


  get processedOptions(): Array<{name: string; value: string; color: string; colorHint: string}>{
    const output: Array<{name: string; value: string; color: string; colorHint: string}> = []

    this.influenceList.sort((a, b) => {
      return Math.abs(parseFloat(b.value)) - Math.abs(parseFloat(a.value));
    });
    //calculate color hint by percentage of biggest color
    if(this.influenceList.length > 0) {
      const maxValue = Math.abs(parseFloat(this.influenceList[0].value));

      this.influenceList.forEach(option => {
        let num = parseFloat(option.value).toFixed(3);
        if(parseFloat(option.value) < 0) {
          if(num == "-0.000") {
            num = "< " + num;
          }
          output.push({name: this.parseLabel(option.name), value: num, color: "rgba(92, 214, 92, 0.7)", colorHint: (100*Math.abs(parseFloat(option.value))/maxValue).toFixed(3)})
        } else if (parseFloat(option.value) > 0) {
          if(num == "0.000") {
            num = "> +" + num;
          } else {
            num = "+" + num;
          }
          output.push({name: this.parseLabel(option.name), value: num, color: "rgba(255,51,51,0.7)", colorHint: (100*Math.abs(parseFloat(option.value))/maxValue).toFixed(3)})
        }
      });
    }
    console.log(output)

    return output;
  }

  parseLabel(optionName: string){
    return (optionName.replace(/([A-Z])/g, ' $1').split("_").map(name => name.charAt(0).toUpperCase()+name.slice(1)).join(" ").split("#").map(name => "# " + name.charAt(0).toUpperCase()+name.slice(1)).join(" ").slice(1))
  }

  nextPage() {
    if (this.page<this.maxPages){
      this.page++;
    }
    }

    lastPage() {
    if (this.page>0){
      this.page--;
    }
    }

}
