
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import Tree from "@/components/Tree.vue";
    import {TreeNode} from "@/types/TreeNode";

    @Component({components: {Tree}})
    export default class OptionContainer extends Vue {
      @Prop() private trees!: Array<TreeNode>;
      private position = 0;
      private x0 = 0;
      private y0 = 0;
      private locked = false;
      private relposition = 0;

      private depthSearch(node: TreeNode, depth: number): number{
        if (!node.expand||node.children.length === 0){
          return depth;
        }
        let max = depth;
        for (let i = 0; i < node.children.length; i++){
          const newdepth = this.depthSearch(node.children[i],depth+1);
          if (newdepth > max){
            max = newdepth;
          }
        }
        return max;
      }

      get maxPosition(): number{
        let max = 1;
        for (let i = 0; i < this.trees.length; i++){
          const newdepth = this.depthSearch(this.trees[i],1);
          if (newdepth>max){
            max = newdepth;
          }
        }
        return max-1;
      }

      get classname(): string{
        return (!this.locked ? 'option-container-not-moving' : '')
      }

      get w(): number{
        return window.innerWidth;
      }

      get marginTransform(): string{
        return "margin-left:"+(30+((this.position + this.relposition)*-231) )+"px;"
      }

      private unify(e: TouchEvent | Touch) {
        if ('changedTouches' in e){
          return e.changedTouches[0]
        }
        return e
      }

      private touchStart(e: Touch){
        this.x0 = this.unify(e).clientX;
        this.y0 = this.unify(e).screenY;
        this.locked = true;
      }

      private touchMove(e: TouchEvent){
        //e.preventDefault();
        if (this.locked) {
          const dy = this.unify(e).screenY - this.y0;
          if (dy > 30){
            this.relposition = 0;
            this.locked = false;
            return
          }

          const dx = this.unify(e).clientX - this.x0;
          const f = + (dx/this.w).toFixed(2);
          this.relposition = f*-2.5;
        }
      }

      private touchEnd(){

        if (this.locked) {
          if (Math.abs(this.relposition) > 0.4){
            this.position += (Math.sign(this.relposition));
          }
          if (this.position>this.maxPosition){
            this.position = this.maxPosition;
          }
          if (this.position< 0){
            this.position = 0;
          }
          this.relposition = 0;

          this.x0 = 0;
          this.locked = false;
        }

      }


    }
