
import {Component, Prop, Vue} from 'vue-property-decorator';
import getURL from "@/functions/getURL";

@Component
export default class LogoutButton extends Vue {
  async logout() {
    try{
      const foreignData = await this.$http.post(getURL()+'/logout', {}, {withCredentials: true});
      const dictData = foreignData.data;
      if(dictData.success === true ) {
        this.$emit("loggedOut");

      }
    } catch(e) {
      console.error(e);
    }
  }

}
