import { TreeNode } from '@/types/TreeNode';


function searchTreeNameRec(tree: TreeNode, name: string): TreeNode | undefined {
  if (tree.label === name) {
    return tree;
  }
  for (let i = 0; i < tree.children.length; i++) {
    const result = searchTreeNameRec(tree.children[i], name);
    if (result) {
      return result;
    }
  }
  return undefined;
}

export default function searchTreeName(trees: Array<TreeNode>, name: string): TreeNode | undefined{
  let output;
  for (let i = 0; i < trees.length; i++){
    output = searchTreeNameRec(trees[i], name);
    // Beende den loop wenn der parent gefunden wurde.
    if (output){
      return output;
    }
  }
  return undefined;
}
