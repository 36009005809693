
import { Component, Prop, Vue } from 'vue-property-decorator';
import SavedConfigViewer from '@/components/SavedConfigViewer.vue';
import TopConfigViewer from './TopConfigViewer.vue';
import { CostObject } from '@/types/CostObject';
import getURL from '@/functions/getURL';

@Component({components: {SavedConfigViewer, TopConfigViewer}})
export default class ManageFooter extends Vue {
    private configs: Array<object> = [];
    private items = 3;
    private configCount = 3;
    private loaded = false;
    private userLoggedIn = true;
    private showList = true;
    private selectedConfigList = 'ownConfigs';
    private maxCosts: Array<CostObject> = [];
    private minCosts: Array<CostObject> = [];
    @Prop() private selectedModel!: number;

    loadConfig1(configID: number, configName: string) {
      this.$emit('loadConfig1', configID, configName)
    }

    loadConfig2(configID: number, configName: string) {
      this.$emit('loadConfig2', configID, configName)
    }

    closeConfigList() {
      this.showList = false;
    }

    openConfigList() {
      this.showList = true;
    }

    async updateMaxCosts(value: number){
      const foreignData = await this.$http.post(getURL()+'/most_expensive_config/'+value.toString());
      const dataDict = foreignData.data;

      for(const nameRaw in dataDict) {
        const raw = nameRaw.split("(");
        const name = raw[0];
        const unit = raw[1].split(";")[0];
        const cost_value = dataDict[nameRaw];
        if (!cost_value) {
          this.maxCosts = [];
          break;
        }
        const split = name.split("_");

        for(let i = 0; i < split.length; i++){
          split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
        }
        const displayName = split.join(" ");
        this.maxCosts.push({
          name: displayName,
          value: cost_value,
          unit: unit,
          kpiName: "Placeholder",
          program_id: value
        })
      }
    }

  async updateMinCosts(value: number){
    const foreignData = await this.$http.post(getURL()+'/least_expensive_config/'+value.toString());
    const dataDict = foreignData.data;

    for(const nameRaw in dataDict) {
      const raw = nameRaw.split("(");
      const name = raw[0];
      const unit = raw[1].split(";")[0];
      const cost_value = dataDict[nameRaw];
      if (!cost_value) {
        this.minCosts = [];
        break;
      }
      const split = name.split("_");

      for(let i = 0; i < split.length; i++){
        split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
      }
      const displayName = split.join(" ");
      this.minCosts.push({
        name: displayName,
        value: cost_value,
        unit: unit,
        kpiName: "Placeholder",
        program_id: value
      })
    }
  }

    mounted() {
      this.updateMaxCosts(1);
      this.updateMaxCosts(2);
      this.updateMinCosts(1);
      this.updateMinCosts(2);
      console.log(this.maxCosts);
    }

}
