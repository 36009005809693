
import SignUpForm from "@/components/SignUpForm.vue";
import {Component, Vue} from 'vue-property-decorator';
import OptionContainer from "../components/OptionContainer.vue";

@Component({components:{OptionContainer, SignUpForm}})
export default class SignUp extends Vue {

}

