import Vue from 'vue';
// @ts-ignore
import axios from 'axios'
// @ts-ignore
import VueAxios from 'vue-axios'
// @ts-ignore
import VueCookies from'vue-cookies'

Vue.use(VueCookies)

Vue.use(VueAxios, axios)
// @ts-ignore
import Vue2OrgTree from 'vue2-org-tree';
Vue.use(Vue2OrgTree);
// @ts-ignore
// import sunburst from 'vue-d3-sunburst';
// Vue.use(sunburst);
// import d3 from 'd3';
// Vue.use(d3);
import App from './App.vue';
import router from './router';


Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
