export type CostObject = {
  name: string;
  value: string;
  unit: string;
  kpiName: string;
  program_id: number;
}

export default function fromJSON(key: string, value: string, program_id: number): CostObject {
  const raw = key.split("(");
  const name = raw[0];
  const unit  = raw[1].split(";")[0];
  const split = name.split("_");
  for(let i = 0; i < split.length; i++){
    split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
  }
  const displayName = split.join(" ");

  return {
    name: displayName,
    value: value,
    unit: unit,
    kpiName: "fall-back",
    program_id: program_id
  }
}
